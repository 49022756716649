@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

* a {
  text-decoration: none;
  color: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

@media screen and (max-width:992px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #19a68d;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #19a68d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDataGrid-columnHeaders {
  background-color: #00998C;
  color: #fff;
}

.slick-slide>div {
  margin-left: 10px;
}

.slick-list {
  margin-left: 10px;
}